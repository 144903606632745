import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Columns, Container, Heading, Icon, Loader, Section, Table } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { useOrders, useOrdersRequest } from '../../stores/orders'
import { endOfYear, startOfYear } from 'date-fns'
import { useFirstRender } from '../../hooks/use-first-render'
import YearSelect from '../../components/year-select'
import { useTranslation } from 'react-i18next'

const InvoicesPage = () => {
  const [state, actions] = useOrders()

  const firstRender = useFirstRender()

  const [request] = useOrdersRequest({ key: 'get /orders/mafact' })

  const { t } = useTranslation('invoices')


  React.useEffect(() => {
    if (!state.mafact[0] || !firstRender)
      actions.fetchMafact(startOfYear(new Date(state.filters.year.toString())), endOfYear(new Date(state.filters.year.toString()).getTime()))
  }, [state.filters])

  return (
    <Layout pro>

      <Section>
        <Container>
          <Columns>
            <Columns.Column>
              <Heading size={5} mb={5} textColor='dark' textWeight='normal'>
                {t('title')}
              </Heading>
              <YearSelect min={1990} onChange={(year) => actions.setFilters({ year })} defaultValue={state.filters.year}/>
              <Table.Container>
                <Table textAlign='right' hoverable size='fullwidth'>
                  <thead>
                    <tr>
                      <td style={{ width: 150 }}>{t('date')}</td>
                      <td>{t('number')}</td>
                      <td>{t('price')}</td>
                      <td>{t('link')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      state.mafact.filter(order => order.Statut === 'SENDED').map(order =>
                        <tr key={order.Mafact}>
                          <td>{order.Date}</td>
                          <td>{order.Mafact}</td>
                          <td>{order.Total} €</td>
                          <td>
                            <a
                              href={ `${process.env.API_HOST}/orders/${order.Mafact}/download` } target='_blank' rel='noreferrer'>
                              <Icon textColor='primary'>
                                <FontAwesomeIcon icon={faLink} />
                              </Icon>
                            </a>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
                {
                  ((!request || request.status === 'inProgress') && !state.mafact[0]) &&
                    <Loader mt={5} style={{ fontSize: 30 }}/>
                }
              </Table.Container>
            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}

export default InvoicesPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`